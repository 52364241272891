<template>
  <q-card square flat class="bg-transparent">
    <div class="row">
      <div class="col text-body1 text-weight-medium text-accent q-pb-lg">
        <div class="text-caption text-grey-8">Fordon</div>
        {{ arrival.vehicle }}
      </div>

      <div
        class="col text-body1 text-weight-medium text-accent q-pb-lg text-right"
      >
        <div class="text-caption text-grey-8">Trafikdatum</div>
        {{ formatedTrafficDate }}
      </div>
      <slot />
    </div>
  </q-card>
</template>

<script lang="ts">
import { Arrival } from '@/types/arrival'
import { format } from 'date-fns'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ArrivalHeader',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  setup(props) {
    const formatedTrafficDate = computed(() => {
      return format(new Date(props.arrival.trafficDate), 'yyyy-MM-dd')
    })

    return {
      formatedTrafficDate,
    }
  },
})
</script>
