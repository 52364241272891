import { render } from "./ArrivalTask.vue?vue&type=template&id=8ff5caf4"
import script from "./ArrivalTask.vue?vue&type=script&lang=ts"
export * from "./ArrivalTask.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSeparator});
