<template>
  <transition
    enter-active-class="animated animate__fadeInDown"
    leave-active-class="animated animate__fadeOutDown"
    mode="out-in"
    :duration="140"
  >
    <div v-if="loading" class="full-width self-center text-center">
      <q-spinner color="accent" size="3em" />
    </div>
    <template v-else>
      <slot />
    </template>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppLoadingSlot',

  props: {
    loading: Boolean,
  },
})
</script>
