
import { ArrivalTask } from '@/types/arrival-task'
import { computed, defineComponent, PropType } from 'vue'
import AppBtn from '@/components/AppBtn.vue'
import { format } from 'date-fns'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'ArrivalTask',

  components: {
    AppBtn,
  },

  props: {
    arrivalUuid: {
      type: String,
      required: true,
    },
    arrivalTask: {
      type: Object as PropType<ArrivalTask>,
      required: true,
    },
  },

  emits: ['completed'],

  setup(props, { emit }) {
    const { completeTask, loading } = useArrival()
    const completedDate = computed(() => {
      if (!props.arrivalTask.completed) return ''
      return format(new Date(props.arrivalTask?.completed), 'yy/MM/dd HH:mm')
    })

    async function onCompleteTask() {
      const arrivalTask = await completeTask(
        props.arrivalUuid,
        props.arrivalTask.uuid
      )
      emit('completed', arrivalTask)
    }

    return {
      loading,
      onCompleteTask,
      completedDate,
    }
  },
})
