
import { Arrival } from '@/types/arrival'
import { format } from 'date-fns'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ArrivalHeader',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  setup(props) {
    const formatedTrafficDate = computed(() => {
      return format(new Date(props.arrival.trafficDate), 'yyyy-MM-dd')
    })

    return {
      formatedTrafficDate,
    }
  },
})
