<template>
  <q-card square>
    <div class="row">
      <div class="col text-h6 text-weight-bold text-accent q-pa-md">
        {{ arrivalTask.task.name }}
      </div>
    </div>
    <q-separator />
    <div class="row">
      <AppBtn
        :loading="loading.completeTaskUuid === arrivalTask.uuid"
        @click="onCompleteTask"
        align="between"
        class="full-width"
        flat
        :disabled="arrivalTask.completed"
        :color="arrivalTask.completed ? 'primary' : 'grey-8'"
        :icon-right="
          arrivalTask.completed
            ? 'mdi-check-circle-outline'
            : 'mdi-checkbox-blank-circle-outline'
        "
      >
        <div class="flex column jutify-start items-start">
          <div class="text-body1 text-weight-medium">
            {{ arrivalTask.completed ? 'Utförd' : 'Ej utförd' }}
          </div>
          <div class="text-grey-7 text-caption" v-if="arrivalTask.completed">
            {{ completedDate }}
          </div>
        </div>
      </AppBtn>
    </div>
  </q-card>
</template>

<script lang="ts">
import { ArrivalTask } from '@/types/arrival-task'
import { computed, defineComponent, PropType } from 'vue'
import AppBtn from '@/components/AppBtn.vue'
import { format } from 'date-fns'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'ArrivalTask',

  components: {
    AppBtn,
  },

  props: {
    arrivalUuid: {
      type: String,
      required: true,
    },
    arrivalTask: {
      type: Object as PropType<ArrivalTask>,
      required: true,
    },
  },

  emits: ['completed'],

  setup(props, { emit }) {
    const { completeTask, loading } = useArrival()
    const completedDate = computed(() => {
      if (!props.arrivalTask.completed) return ''
      return format(new Date(props.arrivalTask?.completed), 'yy/MM/dd HH:mm')
    })

    async function onCompleteTask() {
      const arrivalTask = await completeTask(
        props.arrivalUuid,
        props.arrivalTask.uuid
      )
      emit('completed', arrivalTask)
    }

    return {
      loading,
      onCompleteTask,
      completedDate,
    }
  },
})
</script>
