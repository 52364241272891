<template>
  <q-card style="width: 400px">
    <q-form @submit.prevent="onConfirm" autocomplete="off" greedy>
      <q-card-section>
        <div class="text-h6">Klaramarkera</div>
      </q-card-section>
      <q-separator />

      <q-card-section>
        Vill du klaramarkera checklista för fordon:
        <strong>{{ arrival.vehicle }}</strong>
      </q-card-section>

      <q-separator />
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="$emit('close')"
          :disable="loading.checkOut"
        />
        <q-btn
          flat
          label="Klarmarkera"
          color="primary"
          type="submit"
          :loading="loading.checkOut"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { Arrival } from '@/types/arrival'
import { defineComponent, PropType } from 'vue'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'ArrivalConfirmCheckOutModal',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  emits: ['close', 'check-out'],

  setup(props, { emit }) {
    const { loading, checkOut } = useArrival()
    async function onConfirm() {
      await checkOut(props.arrival.uuid)
      emit('check-out')
    }

    return {
      loading,
      onConfirm,
    }
  },
})
</script>
