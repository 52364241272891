import { render } from "./Checklist.vue?vue&type=template&id=6cf43d20"
import script from "./Checklist.vue?vue&type=script&lang=ts"
export * from "./Checklist.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QPageSticky});
