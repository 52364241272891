
import { Arrival } from '@/types/arrival'
import { defineComponent, PropType } from 'vue'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'ArrivalConfirmCheckOutModal',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  emits: ['close', 'check-out'],

  setup(props, { emit }) {
    const { loading, checkOut } = useArrival()
    async function onConfirm() {
      await checkOut(props.arrival.uuid)
      emit('check-out')
    }

    return {
      loading,
      onConfirm,
    }
  },
})
