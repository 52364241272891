
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppLoadingSlot',

  props: {
    loading: Boolean,
  },
})
