<template>
  <q-page class="q-pa-md flex" style="padding-bottom: 56px">
    <q-dialog v-model="showConfirmModal" persistent>
      <ArrivalConfirmCheckOutModal
        :arrival="arrival"
        v-if="showConfirmModal"
        @close="showConfirmModal = false"
        @check-out="onCheckOut"
      />
    </q-dialog>
    <AppLoadingSlot :loading="loading.get">
      <div class="full-width">
        <ArrivalHeader :arrival="arrival" />
        <ArrivalTask
          v-for="arrivalTask in arrival.arrivalTasks"
          :key="arrivalTask.uuid"
          :arrival-uuid="arrival.uuid"
          :arrivalTask="arrivalTask"
          class="q-mb-md"
          @completed="onCompleted"
        />
      </div>
    </AppLoadingSlot>
    <q-page-sticky position="bottom" class="full-width">
      <AppBtn
        color="primary"
        style="width: 100vw"
        label="Klarmarkera"
        v-if="showCheckOut"
        @click="showConfirmModal = true"
      />
    </q-page-sticky>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useArrival } from '@/composables/useArrival'
import { useRoute, useRouter } from 'vue-router'
import AppLoadingSlot from '@/components/AppLoadingSlot.vue'
import ArrivalHeader from '@/components/arrival/ArrivalHeader.vue'
import ArrivalTask from '@/components/arrival/ArrivalTask.vue'
import AppBtn from '@/components/AppBtn.vue'
import { ArrivalTask as ArrivalTaskType } from '@/types/arrival-task'
import ArrivalConfirmCheckOutModal from '@/components/arrival/ArrivalConfirmCheckOutModal.vue'

export default defineComponent({
  name: 'CheckList',

  components: {
    AppBtn,
    AppLoadingSlot,
    ArrivalHeader,
    ArrivalTask,
    ArrivalConfirmCheckOutModal,
  },

  setup() {
    const { loading, fetchArrival, arrival } = useArrival()
    const route = useRoute()
    const router = useRouter()
    const arrivalUuid = route.params.arrivalUuid
    const showConfirmModal = ref(false)

    if (typeof arrivalUuid === 'string') {
      fetchArrival(arrivalUuid)
    }

    function onCompleted(arrivalTask: ArrivalTaskType) {
      if (!arrival.value) return
      arrival.value.arrivalTasks = arrival.value.arrivalTasks.map((at) => {
        if (at.uuid === arrivalTask.uuid) {
          return arrivalTask
        }

        return at
      })
    }

    const showCheckOut = computed(() => {
      if (!arrival.value) return false
      return (
        arrival.value.status?.status !== 'avslutad' &&
        arrival.value.arrivalTasks.every((at) => at.completed)
      )
    })

    function onCheckOut() {
      router.push(
        `/mobile/wash-list/${route.params.rampUuid}/${route.params.trafficDate}`
      )
    }

    return {
      showCheckOut,
      showConfirmModal,
      onCompleted,
      arrival,
      loading,
      onCheckOut,
    }
  },
})
</script>
